import { useEffect } from 'react';

const handlePostMessage = ({ data }: MessageEvent) => {
  if (data.eventType) {
    window.dispatchEvent(
      new CustomEvent(data.eventType, { detail: data.payload })
    );
  }
};

export const useIFrameEvents = () => {
  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);
};
