const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 28 * DAY;
const YEAR = 365 * DAY;
const DEFAULT_TIMEZONE = 'America/New_York';

module.exports = {
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  DEFAULT_TIMEZONE,
};
