import React from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import logo from '../../images/gigg-logo.svg';
import styles from './MainFooter.module.scss';

interface MainFooterProps {}

const MainFooter: React.FC<MainFooterProps> = () => {
  const navigate = useNavigate();
  const { isTabletOrMobile, isLandscape } = useIsTabletOrMobile();
  const navigateToHomePage = () => {
    navigate('/');
  };
  return (
    <div
      className={classNames(styles.mainFooter, {
        [styles.hidden]: isTabletOrMobile || isLandscape,
      })}
    >
      <div className={styles.mainFooterContent}>
        <nav aria-label="secondary" className={styles.mainFooterNavContainer}>
          <ul className={styles.mainFooterNav}>
            <li className={styles.mainFooterNavItem}>
              <a href="https://gigg.com">Terms</a>
            </li>
            <li className={styles.mainFooterNavItem}>
              <a href="http://gigg.com">Privacy Policy</a>
            </li>
          </ul>
        </nav>
        <address className={styles.mainFooterContactInfo}>
          <a href="mailto:support@gigg.com">SUPPORT@GIGG.COM</a>
        </address>
        <div className={styles.mainFooterContentRight}>
          <img src={logo} onClick={navigateToHomePage} alt="Gigg logo" />
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
