import React from 'react';
import CompetitionTile from '../../components/CompetitionTile';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import PastCompetitionsHero from '../../components/PastCompetitionsHero';
import { mapStageTypeToCompetitionTile } from '../../functions/map-stage-type-to-competition-tile.function';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import { useHomepageCompetitions } from '../../services/useHomepageCompetitions';
import styles from './PastCompetitions.module.scss';

const PastCompetitions: React.FC = () => {
  const { data: results } = useHomepageCompetitions();
  const { isTabletOrMobile, isLandscape } = useIsTabletOrMobile();
  const isMobile = isTabletOrMobile || (isLandscape && !isTabletOrMobile);
  return (
    <div className={styles.pastCompetitions}>
      <MainHeader />
      <div className={styles.pastCompetitionsContent}>
        <section>
          {!isMobile && (
            <div className={styles.pastCompetitionsHero}>{results && <PastCompetitionsHero />}</div>
          )}
          <div className={styles.pastCompetitionsCompetitionsContainer}>
            <div className={styles.pastCompetitionsHeading}>
              <h1>Past Challenges</h1>
            </div>
            <div className={styles.pastCompetitionsCompetitions}>
              {results ? (
                results.pastCompetitions.map((comp) => {
                  return (
                    <CompetitionTile
                      key={comp.competitionId}
                      thumbnail={comp.thumbnail}
                      competitionName={comp.competitionName}
                      hostName={comp.channelName}
                      startDate={comp.startDate}
                      endDate={comp.endDate}
                      paused={false}
                      cta={mapStageTypeToCompetitionTile(comp.stageType)}
                      link={`/channel/${comp.orgUrl}/${comp.competitionId}`}
                    />
                  );
                })
              ) : (
                <>
                  <div className={styles.pastCompetitionsCompetition}></div>
                  <div className={styles.pastCompetitionsCompetition}></div>
                  <div className={styles.pastCompetitionsCompetition}></div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </div>
  );
};

export default PastCompetitions;
