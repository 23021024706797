import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import logo from '../../images/gigg-logo.svg';
import { SearchResult, useSearch } from '../../services/useSearch';
import MobileNavigation from '../MobileNavigation';
import MobileSearch from '../MobileSearch';
import SearchField from '../SearchField';
import styles from './MainHeader.module.scss';
import { debounce } from 'throttle-debounce';

interface MainHeaderProps {}

const MainHeader: React.FC<MainHeaderProps> = () => {
  const [query, setQuery] = useState('');
  const { isTabletOrMobile, isLandscape } = useIsTabletOrMobile();
  const { data: results, isLoading: isSearching } = useSearch(query);
  const navigate = useNavigate();
  const handleItemChange = (selectedItem: SearchResult) => {
    if (selectedItem.type === 'channel') {
      navigate(`/channel/${selectedItem.orgUrl}`);
    } else {
      navigate(`/channel/${selectedItem.orgUrl}/${selectedItem.competitionId}`);
    }
  };
  const handleChange = debounce(500, (value: string) => {
    setQuery(value);
  });

  const navigateToHomePage = () => {
    navigate('/');
  };

  const isMobileView = isTabletOrMobile || isLandscape;

  return (
    <header className={styles.mainHeader}>
      <div className={styles.mainHeaderContent}>
        <div className={styles.mobileNav}>
          <MobileNavigation />
        </div>
        <div className={styles.logo}>
          <img src={logo} onClick={navigateToHomePage} alt="Gigg logo" />
        </div>
        {isMobileView && (
          <MobileSearch
            className={styles.mobileSearch}
            onChange={handleChange}
            onItemChange={handleItemChange}
            options={results}
          />
        )}
        {!isMobileView && (
          <SearchField
            className={styles.searchField}
            onItemChange={handleItemChange}
            onChange={handleChange}
            isSearching={isSearching}
            options={results}
          />
        )}
      </div>
    </header>
  );
};

export default MainHeader;
