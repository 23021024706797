import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyD3s7uECBOKAszUvPHQYfKJ3P6tRDFqNz4',
  authDomain: 'social-auth.gigg.com',
  projectId: 'gigg-social-inbox',
  appId: '1:230252609063:web:408dea95cf28df59',
};

const firebase = initializeApp(firebaseConfig);
export default firebase;
