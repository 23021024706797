import { useMediaQuery } from 'react-responsive';

export const useIsTabletOrMobile = (): {
  isTabletOrMobile: boolean,
  isLandscape: boolean
} => {
  return {
    isTabletOrMobile: useMediaQuery({ query: '(max-width: 768px)' }),
    isLandscape: useMediaQuery({ query: '(device-width: 1024px) and (device-height: 768px) and (orientation: landscape)'})
  }
};