import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CompetitionTile from '../../components/CompetitionTile';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import MainHero from '../../components/MainHero';
import PastCompetitionsHero from '../../components/PastCompetitionsHero';
import { mapStageTypeToCompetitionTile } from '../../functions/map-stage-type-to-competition-tile.function';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import { useHomepageCompetitions } from '../../services/useHomepageCompetitions';
import styles from './Home.module.scss';

const Home: React.FC = () => {
  const { data: results } = useHomepageCompetitions();
  const { isTabletOrMobile, isLandscape } = useIsTabletOrMobile();
  const isMobile = isTabletOrMobile || (isLandscape && !isTabletOrMobile);
  const numberOfFeaturedCompetitions = results?.featuredCompetitions?.length as number;
  const hasFeaturedCompetitions = numberOfFeaturedCompetitions > 0;

  return (
    <div className={styles.home}>
      <Helmet>
        <script>
          {`(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2695416,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
      <MainHeader />
      <div className={styles.homeContent}>
        <section>
          {!isMobile && hasFeaturedCompetitions && (
            <div className={styles.homeHero}>
              {results && <MainHero competitions={results?.featuredCompetitions} />}
            </div>
          )}
          <div className={styles.homeCompetitionsContainer}>
            <div className={styles.homeCompetitions}>
              {results ? (
                results.homepageCompetitions.map((comp) => {
                  return (
                    <CompetitionTile
                      key={comp.competitionId}
                      thumbnail={comp.thumbnail}
                      competitionName={comp.competitionName}
                      hostName={comp.channelName}
                      startDate={comp.startDate}
                      endDate={comp.endDate}
                      paused={false}
                      cta={mapStageTypeToCompetitionTile(comp.stageType)}
                      link={`/channel/${comp.orgUrl}/${comp.competitionId}`}
                    />
                  );
                })
              ) : (
                <>
                  <div className={styles.homeCompetition}></div>
                  <div className={styles.homeCompetition}></div>
                  <div className={styles.homeCompetition}></div>
                </>
              )}
            </div>
          </div>
        </section>
        {!isMobile && (
          <div className={styles.homePastCompetitions}>
            <h1 className={styles.homePastCompetitionsHeading}>
              Past Challenges{' '}
              <Link className={styles.homePastCompetitionsHeading} to={'/pastchallenges'}>
                <span className={styles.homePastCompetitionsSeeMore}>(see more)</span>
              </Link>
            </h1>
          </div>
        )}
        <section className={styles.homeSection}>
          {!isMobile && (
            <div className={styles.homePastCompetitionsHero}>
              {results && <PastCompetitionsHero />}
            </div>
          )}
          <div className={styles.homePastCompetitionsContainer}>
            {isMobile && (
              <h1 className={styles.homePastCompetitionsHeading}>
                Past Challenges{' '}
                <Link className={styles.homePastCompetitionsHeading} to={'/pastchallenges'}>
                  <span className={styles.homePastCompetitionsSeeMore}>(see more)</span>
                </Link>
              </h1>
            )}
            <div className={styles.homeCompetitions}>
              {results ? (
                results.pastCompetitions.slice(0, 10).map((comp) => {
                  return (
                    <CompetitionTile
                      key={comp.competitionId}
                      thumbnail={comp.thumbnail}
                      competitionName={comp.competitionName}
                      hostName={comp.channelName}
                      startDate={comp.startDate}
                      endDate={comp.endDate}
                      paused={false}
                      cta={mapStageTypeToCompetitionTile(comp.stageType)}
                      link={`/channel/${comp.orgUrl}/${comp.competitionId}`}
                    />
                  );
                })
              ) : (
                <>
                  <div className={styles.homeCompetition}></div>
                  <div className={styles.homeCompetition}></div>
                  <div className={styles.homeCompetition}></div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <MainFooter />
    </div>
  );
};

export default Home;
