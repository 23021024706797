import React, { useEffect, useCallback, useState, memo } from 'react';
import * as workerTimers from 'worker-timers';
import styles from './Countdown.module.scss';
import clock from '../../images/gold-clock.svg';

const leadingZero = (num: number): string => {
  return num < 10 ? '0' + num : num + '';
};

const getDays = (time: number) =>
  leadingZero(Math.floor(time / (1000 * 60 * 60 * 24)));
const getHours = (time: number) =>
  leadingZero(Math.floor((time / (1000 * 60 * 60)) % 24));
const getMinutes = (time: number) =>
  leadingZero(Math.floor((time / 1000 / 60) % 60));
const getSeconds = (time: number) =>
  leadingZero(Math.floor((time / 1000) % 60));

const timeSuffix = {
  DAYS: 'd',
  HOURS: 'h',
  MINUTES: 'm',
  SECONDS: 's',
};

export interface CountdownProps {
  deadline: string;
  text: string;
}

export interface CountdownNodeProps {
  display: string;
  label: string;
}

const initialTime = ['00', '00', '00', '00'];

const CountdownNode: React.FC<CountdownNodeProps> = ({ display, label }) => {
  return (
    <>
      <div className={styles.countdownNode}>
        {display} {label}
      </div>
      {label !== timeSuffix.SECONDS && <span>|</span>}
    </>
  );
};

const Countdown: React.FC<CountdownProps> = ({ deadline, text }) => {
  const [time, setTime] = useState(initialTime);

  const getTimeUntil = useCallback((deadline) => {
    const time = new Date(deadline).getTime() - new Date().getTime();
    if (time < 0) {
      setTime(initialTime);
    } else {
      setTime([
        getDays(time),
        getHours(time),
        getMinutes(time),
        getSeconds(time),
      ]);
    }
  }, []);

  useEffect(() => {
    const timerRef = workerTimers.setInterval(
      () => getTimeUntil(deadline),
      1000
    );

    return () => {
      workerTimers.clearInterval(timerRef);
      getTimeUntil(deadline);
    };
  }, [deadline, getTimeUntil]);

  const [days, hours, minutes, seconds] = time;

  return (
    <div className={styles.countdown}>
      <img alt="clock icon" src={clock} style={{ marginRight: '4px' }} />
      <span className={styles.countdownText}>{text}</span>
      <CountdownNode display={days} label={timeSuffix.DAYS} />
      <CountdownNode display={hours} label={timeSuffix.HOURS} />
      <CountdownNode display={minutes} label={timeSuffix.MINUTES} />
      <CountdownNode display={seconds} label={timeSuffix.SECONDS} />
    </div>
  );
};

export default memo(Countdown);
