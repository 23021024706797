import { useCombobox } from 'downshift';
import { SearchResult } from '../services/useSearch';

const itemToString = (item: SearchResult | null) => (item ? item.label : '');

interface TypeaheadProps {
  onChange?: (value: string) => void;
  onItemChange: (item: SearchResult) => void;
  inputValue?: string;
  options?: SearchResult[];
}

const useTypeahead = ({
  options = [],
  inputValue,
  onChange,
  onItemChange,
}: TypeaheadProps) => {
  return useCombobox({
    items: options,
    inputValue,
    itemToString,
    defaultHighlightedIndex: 0,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        onItemChange(changes.selectedItem);
      }
    },

    onInputValueChange: ({ inputValue }) => {
      if (onChange) {
        onChange(inputValue || '');
      }
    },
  });
};

export default useTypeahead;
