import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';
import styles from './Competition.module.scss';
import { useCurrentStageView, useCompetitionType } from '../../services/useCompetitionEmbed';
import PageSkeleton from '../../components/PageSkeleton';
import { STAGE_TYPES } from '../../consts';
import { useIFrameEvents } from '../../hooks/useIFrameEvents';

const Competition: React.FC = () => {
  // Makes custom 'history', 'fullscreen' and 'navigate' events available from iframe.
  useIFrameEvents();
  const { compId = '' } = useParams();
  const { data: result, isLoading } = useCompetitionType(compId);
  const initialStage = useCurrentStageView(compId) || '';
  const [currentStagePath, setCurrentStagePath] = useState('/');
  const stageView = useCurrentStageView(compId) || '';
  const isMobile = window.innerWidth < 745;
  const navigate = useNavigate();

  const hashNavigate = (hash: string) => {
    const urlObj = new URL(document.URL);
    urlObj.hash = hash;
    document.location.href = urlObj.toString();
  };

  const handleScriptLoad = useCallback(() => {
    bragify.giggCompetition({
      compId: compId,
      type: result?.metadata?.submissionType?.toLocaleLowerCase(),
      domElementId: 'gigg-competition',
      local: true,
    });
  }, [compId, result?.metadata?.submissionType]);

  useEffect(() => {
    if (!isLoading && result) {
      const script = document.createElement('script');
      script.src = `${process.env.REACT_APP_JS_URL}/${process.env.REACT_APP_EMBED_FILE}`;
      script.async = true;
      script.onload = handleScriptLoad;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [compId, result?.metadata?.submissionType, handleScriptLoad, isLoading, result]);

  useEffect(() => {
    const handleNavigate = (event: any) => {
      const { path } = event?.detail;

      if (path === 'HOME') {
        navigate('/');
      } else {
        setCurrentStagePath(path);
        hashNavigate(path);
      }
    };

    const handleHistory = (event: any) => {
      const { hash } = event?.detail;

      if (hash !== window.location.hash) {
        window.history.back();
      }
    };

    // set intial hash to current stage if none is set
    if (!document.location.hash || document.location.hash === '#/') {
      hashNavigate(currentStagePath);
    }

    window.addEventListener('navigate', handleNavigate);
    window.addEventListener('history', handleHistory);
    return () => {
      window.removeEventListener('navigate', handleNavigate);
      window.removeEventListener('history', handleHistory);
    };
  }, [navigate, currentStagePath, isMobile, compId, result?.metadata?.submissionType]);

  useEffect(() => {
    if (initialStage && result?.metadata.submissionType === 'BRACKET') {
      setCurrentStagePath(`/${STAGE_TYPES[initialStage]?.toLocaleLowerCase()}`);
    }
  }, [initialStage, result?.metadata?.submissionType]);

  if (!currentStagePath) {
    return null;
  }

  return (
    <div className={styles.competition}>
      <div style={{ width: '100%' }}>
        <MainHeader />
      </div>
      <div className={styles.competition__content} id="gigg-competition">
        {(isLoading || !result) && <PageSkeleton stageType={stageView} />}
      </div>
    </div>
  );
};

export default Competition;
