import React, { useState } from 'react';
import classNames from 'classnames';
import useBoolean from '../../hooks/useBoolean';
import searchIcon from '../../images/magnifying-glass.svg';
import xIcon from '../../images/x-icon.svg';
import backIcon from '../../images/back.svg';
import { SearchResult } from '../../services/useSearch';
import useTypeahead from '../../hooks/useTypeahead';
import styles from './MobileSearch.module.scss';
import SearchFieldItem from '../SearchField/SearchFieldItem';

interface MobileSearchProps {
  className?: string;
  options?: SearchResult[];
  onChange?: (value: string) => void;
  onItemChange: (item: SearchResult) => void;
}

const MobileSearch: React.FC<MobileSearchProps> = ({
  className,
  options = [],
  onChange,
  onItemChange,
}) => {
  const { value, toggle } = useBoolean(false);
  const [inputValue, setInputValue] = useState<string | undefined>('');

  const handleChange = (value: string) => {
    setInputValue(value);
    onChange?.(value);
  };

  const { getInputProps, getMenuProps, getItemProps, getComboboxProps, highlightedIndex } =
    useTypeahead({
      options,
      inputValue,
      onChange: handleChange,
      onItemChange,
    });

  return (
    <div
      className={classNames(styles.mobileSearch, className, {
        [styles.mobileSearch__active]: value,
      })}
      {...getComboboxProps()}
    >
      {inputValue ? (
        <button className={styles.mobileSearchButton} onClick={() => handleChange('')}>
          <img src={xIcon} alt="clear" />
        </button>
      ) : (
        <button className={styles.mobileSearchButton} onClick={toggle}>
          <img src={searchIcon} alt="search" />
        </button>
      )}
      <div className={styles.mobileSearchContent}>
        <div className={styles.mobileSearchHeader}>
          <button className={styles.mobileSearchBackBtn} onClick={toggle}>
            <img src={backIcon} alt="back" />
          </button>
          <div className={styles.mobileSearchField}>
            <input
              {...getInputProps({
                placeholder: 'Search for challenges or channels',
              })}
              className={styles.mobileSearchInput}
            />
          </div>
        </div>
        <div className={styles.mobileSearchContentBody} {...getMenuProps()}>
          <ul className={styles.mobileSearchContentList}>
            {options.length > 0 &&
              inputValue &&
              options.map((item, index) => (
                <div {...getItemProps({ item, index })} key={index}>
                  <SearchFieldItem
                    label={item.label}
                    value={item.orgUrl}
                    type={item.type}
                    selected={highlightedIndex === index}
                  />
                </div>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileSearch;
