import React, { useState } from 'react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import prevChevron from '../../images/prev-chevron.svg';
import nextChevron from '../../images/next-chevron.svg';
import styles from './PastCompetitionsHero.module.scss';

const PastCompetitionsHero: React.FC = () => {
  const [hover, setHover] = useState(false);

  const assets = [
    {
      name: 'Imagine Dragons Wrecked Cover Contest',
      thumbnail: 'https://competition-videos.gigg.com/assets/past-hero-imagine-dragons.jpg',
      link: '/channel/umg/6123de74678066000984c61d',
    },
    {
      name: 'Mykayla Skinner Best Fails Challenge',
      thumbnail: 'https://competition-videos.gigg.com/assets/past-hero-mykayla-skinner.jpg',
      link: '/channel/mykaylaskinner/619e857b66c63d00097344e6',
    },
    {
      name: 'BYU Offensive Lineman First Ride Challenge',
      thumbnail: 'https://competition-videos.gigg.com/assets/past-hero-byu-offensive-line.jpg',
      link: '/channel/byufootball/619294fc1ba1f900094c2fcc',
    },
  ];

  const numberofCompetitions = assets.length;

  return (
    <div
      className={styles.pastCompetitionsHero}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        }}
        loop
        autoplay={numberofCompetitions > 1 ? { delay: 5000 } : false}
        speed={1000}
        spaceBetween={0}
        slidesPerView={1}
        style={{ height: '100%' }}
        simulateTouch={false}
      >
        {assets.map((asset) => (
          <SwiperSlide className={styles.pastCompetitionsHeroSlide}>
            <a href={`${asset.link}`}>
              <img
                src={asset.thumbnail}
                alt={`${asset.name}thumbnail`}
                className={styles.pastCompetitionsHeroSlideImg}
              />
            </a>
          </SwiperSlide>
        ))}

        <>
          <div
            className="swiper-pagination"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginLeft: '-15px',
            }}
          />
          <div
            className={classNames(['swiper-button-next'], {
              [styles.hiddenNavigation]: !hover,
            })}
            style={{
              content: `url(${nextChevron})`,
              width: '100px',
              height: '100px',
              top: '40%',
              right: '-5px',
            }}
          />
          <div
            className={classNames(['swiper-button-prev'], {
              [styles.hiddenNavigation]: !hover,
            })}
            style={{
              content: `url(${prevChevron})`,
              width: '100px',
              height: '100px',
              top: '40%',
              left: '-5px',
            }}
          />
        </>
      </Swiper>
    </div>
  );
};

export default PastCompetitionsHero;
