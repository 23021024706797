import { QueryClient, QueryFunction } from 'react-query';
import axios from './axios';

const defaultQueryFn: QueryFunction = async ({ queryKey: [url, params] }) => {
  if (typeof url === 'string') {
    const { data } = await axios(url, { params });
    return data;
  }

  throw new Error('Invalid QueryKey');
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 60000,
    },
  },
});

export default queryClient;
