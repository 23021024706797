import { useQuery } from 'react-query';
import { STAGE_TYPES } from '../consts';

interface Metadata {
  submissionType: string;
}
interface Media {
  images: {
    logo: string;
    introCover: string;
  };
  videos: {
    introVideo: string;
    confirmationVideo: string;
    adFrequency: number;
  };
}

export interface Stage {
  name: string;
}

export interface CompetitionEmbedResult {
  metadata: Metadata;
  media: Media;
  name: string;
  stages: Stage[];
  currentStage: {
    endDate: number;
    stageNum: number;
  };
}

const embedSearchURL = (compId: string) =>
  `/public/competition/${compId}/embeddata`;

export const useCompetitionType = (compId: string) => {
  return useQuery<CompetitionEmbedResult>(embedSearchURL(compId), {
    refetchOnWindowFocus: false,
  });
};

export const useCurrentStageView = (compId: string) => {
  const { data } = useCompetitionType(compId);
  const stageNum = data?.currentStage?.stageNum;
  const lastStageNum = data?.stages?.length;

  if (stageNum === undefined) return;

  if (lastStageNum === stageNum + 1) {
    return STAGE_TYPES.WINNERS;
  }

  if (stageNum === 0) {
    return STAGE_TYPES.SUBMISSION;
  }

  if (stageNum === 1) {
    return STAGE_TYPES.QUALIFYING;
  }

  if (stageNum > 1) {
    return STAGE_TYPES.BRACKET;
  }
};

export const useCompetitionIsFinished = (compId: string) => {
  const { data } = useCompetitionType(compId);
  const stageNum = data?.currentStage?.stageNum;
  const lastStageNum = data?.stages?.length || 0;

  if (stageNum === undefined) return;

  return stageNum >= lastStageNum - 1;
};
