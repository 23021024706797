import { Competition } from '../services/useHomepageCompetitions';

const getMatchProperty = (match: string) => {
  // Strips the {{}}
  return match.slice(2, match.length - 2);
}


export const competitionDescriptionInterpolation = (rawText: string, competition: Competition): string => {
  const matches = rawText.match(/{{([^}]+)}}/gi);
  let text = rawText;
  matches?.forEach(match => {
    const matchProperty = getMatchProperty(match);
    text = text.replace(match, (competition as any)[matchProperty] || '');
  })
  return text;
}

export const shortenHeroDescriptionText = (text: string, maxChars = 100) => {
  return text.length > maxChars ? text.slice(0, maxChars) : text
}