import React from 'react';
import classNames from 'classnames';
import styles from './SearchField.module.scss';

interface SearchFieldItemProps {
  label: string;
  value: string;
  type: string;
  selected: boolean;
}

const SearchFieldItem: React.FC<SearchFieldItemProps> = ({
  label,
  type,
  value,
  selected,
  ...rest
}) => {
  return (
    <li
      className={classNames(styles.item, { [styles.item__selected]: selected })}
      {...rest}
    >
      <div className={styles.itemLabel}>{label}</div>
      <div className={styles.itemType}>
        <span
          className={classNames(styles.itemTypeLabel, {
            [styles.itemTypeLabel__channel]: type === 'channel',
            [styles.itemTypeLabel__active]: type === 'active',
          })}
        >
          {type}
        </span>
      </div>
    </li>
  );
};

export default SearchFieldItem;
