import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from 'react-query';
import queryClient from './app/queryClient';
import Competition from './views/Competition';
import Channel from './views/Channel';
import PastCompetitions from './views/PastCompetitions';
import { AuthProvider } from './services/useAuthProvider';
import './index.scss';

const isProduction = process.env.NODE_ENV === 'production';

ReactDOM.render(
  <CacheBuster
    currentVersion={process.env.REACT_APP_VERSION}
    isEnabled={isProduction}
    isVerboseMode={false}
  >
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              {/* Temporary redirect */}
              <Route
                path="/channel/spacestation/64a2f1adfd314eca106cdc54"
                element={<Navigate replace to="/channel/spacestation/64bdb30ebc076fd2ca4b7aca" />}
              />
              
              <Route path="/" element={<App />} />
              <Route path="/channel/:orgURL" element={<Channel />} />
              <Route path="/channel/:orgURL/:compId" element={<Competition />} />
              <Route path="/pastcompetitions" element={<PastCompetitions />} />
              <Route path="/pastchallenges" element={<PastCompetitions />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
