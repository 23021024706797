import React, { useState } from 'react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Countdown from '../Countdown';
import medal from '../../images/gold-medal.svg';
import prevChevron from '../../images/prev-chevron.svg';
import nextChevron from '../../images/next-chevron.svg';
import { Competition } from '../../services/useHomepageCompetitions';
import { stripHtmlTagsFromText } from '../../functions/strip-html-tags.function';
import { competitionDescriptionInterpolation } from '../../functions/competition-description-interpolation.function';
import genericThumbnail from '../../images/generic-thumbnail.svg';
import { mapStageTypeToHero } from '../../functions/map-stage-types.function';
import styles from './MainHero.module.scss';

interface MainHeroProps {
  competitions: Array<Competition>;
}

const MainHero: React.FC<MainHeroProps> = ({ competitions }) => {
  const [hover, setHover] = useState(false);
  const numberofCompetitions = competitions.length;

  return (
    <div className={styles.mainHeroContainer}>
      <div className={styles.mainHeroContainerLive}>LIVE CHALLENGES</div>
      <div
        className={styles.mainHero}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Swiper
          className={styles.mainHeroSwiper}
          modules={[Pagination, Navigation, Autoplay]}
          navigation={
            numberofCompetitions > 1
              ? {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              : false
          }
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
          }}
          loop
          autoplay={numberofCompetitions > 1 ? { delay: 4000 } : false}
          speed={1000}
          spaceBetween={0}
          slidesPerView={1}
          style={{ height: '100%' }}
          simulateTouch={false}
        >
          {competitions.map((competition) => (
            <div key={competition.competitionId}>
              <SwiperSlide key={competition.competitionId} className={styles.mainHeroSlide}>
                <a
                  href={`/channel/${competition.orgUrl}/${competition.competitionId}`}
                  className={styles.mainHeroLink}
                >
                  <div className={styles.mainHeroSlideInfo}>
                    <div className={styles.mainHeroSlideInfoTitle}>
                      {competition.competitionName}
                      <div className={styles.mainHeroSlideInfoTitleChannel}>
                        by {competition.channelName}
                      </div>
                    </div>
                    <div className={styles.mainHeroSlideInfoDescription}>
                      <div className={styles.mainHeroSlideInfoDescriptionMedal}>
                        <img alt="medal icon" src={medal} />
                        <p className={styles.mainHeroSlideInfoDescriptionMedalText}>
                          {competitionDescriptionInterpolation(
                            stripHtmlTagsFromText(competition.competitionDescription),
                            competition
                          )}
                          ...
                        </p>
                      </div>
                      {competition?.stageEndDate && (
                        <Countdown
                          deadline={new Date(competition?.stageEndDate).toISOString()}
                          text={`${mapStageTypeToHero(competition.stageType)}`}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.mainHeroSlideImgContainer}>
                    <img
                      src={!competition.thumbnail ? genericThumbnail : competition.thumbnail}
                      alt={`${competition.competitionName} thumbnail`}
                      className={styles.mainHeroSlideImg}
                    />
                  </div>
                </a>
              </SwiperSlide>
            </div>
          ))}
          {numberofCompetitions > 1 && (
            <>
              <div
                className="swiper-pagination"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginLeft: '-15px',
                }}
              />
              <div
                className={classNames(['swiper-button-next'], {
                  [styles.hiddenNavigation]: !hover,
                })}
                style={{
                  content: `url(${nextChevron})`,
                  width: '100px',
                  height: '100px',
                  top: '40%',
                  right: '-5px',
                }}
              />
              <div
                className={classNames(['swiper-button-prev'], {
                  [styles.hiddenNavigation]: !hover,
                })}
                style={{
                  content: `url(${prevChevron})`,
                  width: '100px',
                  height: '100px',
                  top: '40%',
                  left: '-5px',
                }}
              />
            </>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default MainHero;
