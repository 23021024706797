import { useQuery } from 'react-query';

export interface SearchResult {
  label: string;
  orgUrl: string;
  competitionId?: string;
  type: 'channel' | 'active' | 'complete';
}

export const useSearch = (query: string) => {
  return useQuery<SearchResult[]>(`/public/temp/search?search=${query}`, {
    enabled: query.length > 0,
    staleTime: 10 * 1000,
  });
};
