import React from 'react';
import MainHeader from '../../components/MainHeader';
import MainFooter from '../../components/MainFooter';
import styles from './Channel.module.scss';
import back from '../../images/back.svg';
import { useParams } from 'react-router';
import { useChannelCompetitions } from '../../services/useChannelCompetitions';
import CompetitionTile from '../../components/CompetitionTile';
import { mapStageTypeToCompetitionTile } from '../../functions/map-stage-type-to-competition-tile.function';

const Channel: React.FC = () => {
  const { orgURL = '' } = useParams();
  const { data: result } = useChannelCompetitions(orgURL);
  return (
    <div className={styles.channel}>
      <MainHeader />
      <div className={styles.channelContent}>
        <div className={styles.channelHeader}>
          <img src={back} alt="back icon" className={styles.channelHeaderBack} />
          <h1 className={styles.channelHeaderName}>{result?.channelName || orgURL}</h1>
        </div>
        <div className={styles.channelCompetitions}>
          {result ? result.competitions.map(comp => (
            <CompetitionTile
              key={comp.competitionId}
              thumbnail={comp.thumbnail}
              competitionName={comp.competitionName}
              hostName={comp.channelName}
              startDate={comp.startDate}
              endDate={comp.endDate}
              paused={false}
              cta={mapStageTypeToCompetitionTile(comp.stageType)}
              link={`/channel/${comp.orgUrl}/${comp.competitionId}`}
            />)
          ) :
            <>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
              <div className={styles.channelCompetition}></div>
            </>
          }
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default Channel;
