import React, { useState } from 'react';
import classNames from 'classnames';
import searchIcon from '../../images/magnifying-glass.svg';
import xIcon from '../../images/x-icon.svg';
import SearchFieldItem from './SearchFieldItem';
import styles from './SearchField.module.scss';
import { SearchResult } from '../../services/useSearch';
import useTypeahead from '../../hooks/useTypeahead';
interface SearchFieldProps {
  onChange?: (value: string) => void;
  onItemChange: (item: SearchResult) => void;
  className?: string;
  options?: SearchResult[];
  isSearching: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  onChange,
  onItemChange,
  className,
  options = [],
  isSearching,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>('');

  const handleChange = (value: string) => {
    setInputValue(value);
    onChange?.(value);
  };

  const {
    getInputProps,
    getMenuProps,
    getItemProps,
    getComboboxProps,
    highlightedIndex,
    isOpen,
  } = useTypeahead({
    options,
    inputValue,
    onChange: handleChange,
    onItemChange,
  });

  return (
    <div
      className={classNames(styles.searchField, className)}
      {...getComboboxProps()}
    >
      <div className={styles.searchFieldInner}>
        <input
          className={styles.searchFieldInput}
          {...getInputProps({ placeholder: 'Search', value: inputValue })}
          alt="Search bar"
          tabIndex={2}
        />
        {inputValue ? (
          <div
            className={styles.searchFieldIcon}
            onClick={() => handleChange('')}
            role="button"
            tabIndex={3}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleChange('');
              }
            }}
          >
            <img src={xIcon} alt="clear" />
          </div>
        ) : (
          <div className={styles.searchFieldIcon} role="button" tabIndex={3}>
            <img src={searchIcon} alt="search button icon" />
          </div>
        )}
      </div>
      <div {...getMenuProps()}>
        {isOpen && inputValue && (
          <ul className={styles.searchFieldMenu}>
            {isSearching && (
              <li className={styles.item} aria-live="polite">
                searching...
              </li>
            )}
            {options.map((item, index) => (
              <SearchFieldItem
                {...getItemProps({ item, index })}
                label={item.label}
                value={item.orgUrl}
                type={item.type}
                key={index}
                selected={highlightedIndex === index}
                aria-selected={highlightedIndex === index}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchField;
