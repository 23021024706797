import { useQuery } from 'react-query';
import { Competition } from './useHomepageCompetitions';

export interface ChannelCompetitionResults {
  channelName: string;
  competitions: Competition[];
}

export const useChannelCompetitions = (channelName: string) => {
  return useQuery<ChannelCompetitionResults>(`/public/temp/result?channel=${channelName}`, {
    staleTime: 10_000,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });
};