import { GIGG_LOGIN_NAME } from './consts';
import { WEEK } from './TimePeriods';
import { SOCIAL_PLATFORMS } from './consts';
import { userType } from './services/useAuthProvider';

export function getCookie(cName: string) {
  const name = `${cName}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getLocal(key: string) {
  const itemStored = window.localStorage.getItem(key);
  if (!itemStored) {
    return null;
  }
  return itemStored;
}

export function getGiggToken() {
  const cookie = getCookie(GIGG_LOGIN_NAME);
  const local = getLocal(GIGG_LOGIN_NAME);
  if ((!cookie && !local) || local === null) {
    return null;
  }
  const { token } = JSON.parse(cookie || local);
  return token;
}

export function invalidateLogin(storedName: string) {
  const d = new Date(0);
  d.setUTCSeconds(WEEK / 1000);
  document.cookie = `${storedName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  localStorage.removeItem(storedName);
}

export const authLogout = ({
  user,
  socialLogout,
  logout,
}: {
  user: userType;
  socialLogout: Function;
  logout: Function;
}): void => {
  if (
    [SOCIAL_PLATFORMS.GOOGLE, SOCIAL_PLATFORMS.FACEBOOK].includes(user.type)
  ) {
    return socialLogout();
  }
  return logout();
};
