import React from 'react';
import genericThumbnail from '../../images/generic-thumbnail.svg';
import styles from './CompetitionTile.module.scss';
import classNames from 'classnames';

interface CompetitionTileProps {
  competitionName: string;
  hostName: string;
  thumbnail: string;
  cta: string;
  startDate: number;
  endDate: number;
  paused: boolean;
  link: string;
}

const CompetitionTile: React.FC<CompetitionTileProps> = ({
  competitionName,
  hostName,
  thumbnail,
  cta,
  startDate,
  endDate,
  paused,
  link,
}) => {
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  const formattedNowDate = new Date();
  const startDateString = formattedStartDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
  const endDateString = formattedEndDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
  return (
    <a
      className={styles.homePastCompetitionsHeading}
      href={link}
    >
      <div className={styles.competitionTile}>
        <img
          className={styles.competitionTileThumbnail}
          src={!thumbnail ? genericThumbnail : thumbnail}
          alt={`${competitionName} thumbnail`}
        />
        <div className={styles.competitionTileTitle}>
          <h1 className={styles.competitionTileTitle__Name}>
            {competitionName}
          </h1>
          <p className={styles.competitionTileTitle__Org}>{hostName}</p>
        </div>
        <div
          className={classNames([styles.competitionTileStatus], {
            [styles.competitionTileStatus__Paused]: paused,
          })}
        >
          <h1 className={styles.competitionTileStatus__Cta}>
            {paused ? 'PAUSED' : cta}
          </h1>
          {formattedNowDate < formattedStartDate ? (
            <p className={styles.competitionTileStatus__Date}>
              {paused
                ? 'CHECK BACK LATER'
                : `STARTS ${startDateString.toUpperCase()}`}
            </p>
          ) : (
            <p className={styles.competitionTileStatus__Date}>
              {paused
                ? 'CHECK BACK LATER'
                : `${
                    formattedNowDate > formattedEndDate ? 'ENDED' : 'ENDS'
                  } ${endDateString.toUpperCase()}`}
            </p>
          )}
        </div>
      </div>
    </a>
  );
};

export default CompetitionTile;
