import { StageTypes } from './map-stage-types.function';

export const mapStageTypeToCompetitionTile = (stageType: StageTypes): string => {
  switch (stageType) {
    case StageTypes.SUBMISSION:
    case StageTypes.SUBMISSIONS:
      return 'Enter To Win';
    case StageTypes.VOTING:
    case StageTypes.BRACKET_VOTING:
      return 'Vote Now';
    case StageTypes.SELECTED:
      return 'Preparing next round';
    case StageTypes.FINISHED:
      return 'See Winner'
  }
}