export const enum StageTypes {
  SUBMISSION = 'SUBMISSION',
  SUBMISSIONS = 'SUBMISSIONS',
  VOTING = 'VOTING',
  SELECTED = 'SELECTED',
  BRACKET_VOTING = 'BRACKET_VOTING',
  FINISHED = 'FINISHED',
}

const StageType = {
  [StageTypes.BRACKET_VOTING]: StageTypes.VOTING,
  [StageTypes.SUBMISSION]: StageTypes.SUBMISSION,
  [StageTypes.SUBMISSIONS]: StageTypes.SUBMISSIONS,
  [StageTypes.SELECTED]: 'PREPARING NEXT ROUND',
  [StageTypes.VOTING]: StageTypes.VOTING,
  [StageTypes.FINISHED]: 'SEE WINNER',
};

export const mapStageTypeToHero = (stageType: StageTypes) => {
  return `${StageType[stageType]} ${
    stageType !== StageTypes.SUBMISSIONS ? 'ENDS:' : 'END:'
  }`;
};
