import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import mobileNav from '../../images/mobile-nav.svg';
import styles from './MobileNavigation.module.scss';
import { BASE_URLS } from '../../consts';
import { useAuth } from '../../services/useAuthProvider';
import { authLogout } from '../../utilities';

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { user, logout, socialLogout } = useAuth();

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    const closeOnScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('scroll', closeOnScroll);
    window.addEventListener('wheel', closeOnScroll);
    document.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('scroll', closeOnScroll);
      window.addEventListener('wheel', closeOnScroll);
      document.removeEventListener('click', clickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.mobileNavigation}>
      <img
        src={mobileNav}
        alt="menu icon"
        className={classNames(styles.mobileNavigationButton, {
          [styles.mobileNavigation__Hidden]: isOpen,
        })}
        onClick={() => setIsOpen(true)}
        role="button"
        tabIndex={4}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsOpen(true);
          } else if (event.key === 'Escape') {
            setIsOpen(false);
          }
        }}
      />
      {isOpen && (
        <>
          <div
            className={styles.overlay}
            onClick={() => setIsOpen(false)}
            onTouchStart={() => setIsOpen(false)}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                setIsOpen(false);
              }
            }}
          ></div>
          <nav className={styles.mobileNavigationMenu} ref={ref}>
            <ul role="menu">
              <li role="menuitem" aria-label="Gigg Business Suite">
                <a
                  href={BASE_URLS.BUSINESS}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Gigg Business Suite
                </a>
              </li>

              <li role="menuitem" aria-label="Terms & Conditions">
                <a
                  href="https://www.gigg.com/termsandconditions/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </li>

              <li role="menuitem" aria-label="Privacy Policy" tabIndex={-1}>
                <a
                  href="https://www.gigg.com/privacy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              {user && (
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => authLogout({ user, socialLogout, logout })}
                  role="menuitem"
                  aria-label="Log out"
                >
                  Log Out
                </li>
              )}
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

export default MobileNavigation;
