import { useQuery } from 'react-query';
import { StageTypes } from '../functions/map-stage-types.function';

export interface Competition {
  competitionId: string;
  competitionName: string;
  competitionDescription: string;
  channelName: string;
  orgUrl: string;
  thumbnail: string;
  stageType: StageTypes;
  stageEndDate?: number;
  startDate: number;
  endDate: number;
}

export interface HomeResults {
  featuredCompetitions: Competition[];
  homepageCompetitions: Competition[];
  pastCompetitions: Competition[];
}

export const useHomepageCompetitions = () => {
  return useQuery<HomeResults>('/public/temp/search?featured=true', {
    staleTime: 10_000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
