import React from 'react';
import classNames from 'classnames';
import playIcon from '../../images/play.svg';
import styles from './PageSkeleton.module.scss';
import { STAGE_TYPES } from '../../consts';
import GiggWatermark from '../GiggWatermark';

interface PageSkeletonProps {
  stageType?: string;
}

const PageSkeleton: React.FC<PageSkeletonProps> = ({ stageType }) => {
  return (
    <div
      className={classNames(styles.pageSkeleton, {
        [styles[`pageSkeleton__${stageType}`]]: stageType,
      })}
    >
      {stageType === STAGE_TYPES.submission && (
        <div className={styles.pageSkeletonBody}>
          <div className={styles.pageSkeletonHeader}></div>
          <div className={styles.pageSkeletonVideo}>
            <img src={playIcon} alt="play" />
          </div>
          <div className={styles.pageSkeletonCopy}>
            <div className={styles.pageSkeletonRow}></div>
            <div className={styles.pageSkeletonRow}></div>
            <div className={styles.pageSkeletonRow}></div>
          </div>
        </div>
      )}
      {stageType === STAGE_TYPES.qualifying && (
        <div className={styles.pageSkeletonQualifying}>
          <div className={styles.pageSkeletonHeader}></div>
          <div className={styles.pageSkeletonActions}>
            <div className={styles.pageSkeletonSearch}></div>
            <div className={styles.pageSkeletonSort}></div>
          </div>
          <div className={styles.pageSkeletonCards}>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
            <div className={styles.pageSkeletonCard}></div>
          </div>
        </div>
      )}
      {(stageType === STAGE_TYPES.bracket ||
        stageType === STAGE_TYPES.winners) && (
        <div className={styles.pageSkeletonBracket}>
          <div className={styles.pageSkeletonWatermark}>
            <GiggWatermark powered animate />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSkeleton;
